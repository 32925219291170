import React from "react";

import birdy from "../../images/bird.svg";

import { StyledImage } from "./styles";

const SuccessIllustration = () => {
  return <StyledImage src={birdy} alt="bird" />;
};

export default SuccessIllustration;

import styled from "styled-components";

export const StyledImage = styled.img`
  width: 35%;
  align-self: flex-end;
  margin: 64px;

  @media (max-width: 850px) {
    width: 100%;
    margin: 24px;
  }
`;

import styled from "styled-components";

export const StyledOprah = styled.img`
  width: 50%;
  border-radius: 16px;

  @media (max-width: 1000px) {
    margin-top: 10%;
    width: 100%;
  }
`;
